import clock from "#/src/assets/svg/clock.svg";
import {ApiArticle} from "#/src/definitions/ApiDefinitions";
import {Link} from "#/src/components/Link";
import {AttachmentNamespace, Attachments} from "#/src/utils/attachment-storage";
import {TimeDisplay} from "#/src/components/TimeDisplay";
import {getProfileUrl} from "#/src/utils/profile-url";
import {navigate} from "vike/client/router";
import {ClickableWrapper} from "#/src/components/ClickableWrapper";
import {AvatarWithFallback} from "#/src/components/AvatarWithFallback";

interface Props {
  article: ApiArticle,
  size?: "high" | "medium" | "small"
}

export function NewestArticle({article, size = "small"}: Props) {
  return <ClickableWrapper onClick={() => navigate(`/blog/${article.slug}`)}>
    <div className="article-xl">
      <div className="article-img">
        <img src={`${Attachments.getUrl(AttachmentNamespace.ARTICLE, article)}-${size}`} alt={article.slug}/>
      </div>
      <div className="article-content">
        <div className="article-tag">
          <a href="#" className="tag-primary">{article.category.name}</a>
        </div>
        <Link href={`/blog/${article.slug}`} className="article-xl-link">
          {article.title}
        </Link>
        <div className="article-author">
          <div className="author">
            <AvatarWithFallback src={Attachments.getUserAvatar(article.author)} alt={article.author.username} className={"avatar avatar-20"}/>
            <a href={getProfileUrl(article.author)} className="author-link">{article.author.username}</a>
          </div>
          <div className="article-date">
            <img src={clock} alt="Icon"/>
            <span><TimeDisplay date={article.creationDate} className={"ms-2"}/></span>
          </div>
        </div>
      </div>
    </div>
  </ClickableWrapper>
}